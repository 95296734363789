<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆列表</el-breadcrumb-item>
      <el-breadcrumb-item>车辆租赁</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <div style="display: flex">
        <el-button style='margin-right: 15px'  type="primary" @click='opentan()'>添加信息</el-button>
        <!-- <el-input style='width: 300px;' placeholder="请输入内容"  v-model="sosuotext" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click='sosuofun()'></el-button>
        </el-input>-->
      </div>

      <!--    列表开始-->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column prop="get_auto_info.auto_number" label="车辆" ></el-table-column>
        <el-table-column prop="start_time_at" label="租赁开始日期"></el-table-column>
        <el-table-column prop="lease_month" label="租赁时长" ></el-table-column>
         <el-table-column label="租赁费用" >
           <template slot-scope="scope">
           {{scope.row.lease_money/100}}元
             </template>
         </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>

            <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog
      title="租赁信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="100px" :rules="drules">
        <el-form-item label="车辆" prop="name">
          <div class="el-select">
            <el-autocomplete
              v-model="dform.auto_number"
              @select="openxuanfun"
              :debounce="1000"
              :fetch-suggestions="querySearch"
              placeholder="车辆"
              value-key="auto_number"
            ></el-autocomplete>
          </div>
        </el-form-item>
        <el-form-item label="租赁开始日期"  prop="data1">
        <el-date-picker
          v-model="dform.start_time_at"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="租赁开始日期">
        </el-date-picker>
      </el-form-item>
        <el-form-item label="租赁时长" prop="data2">
          <el-input v-model="dform.lease_month" placeholder="租赁时长"></el-input>
        </el-form-item>
      <el-form-item label="租赁费用" prop="cost">
        <div class='el-select'> <el-input v-model='dform.lease_money'   placeholder="租赁费用">>
          <i slot="prefix" class="iconfont icon-jiage-copy"></i>
        </el-input></div>

      </el-form-item>
        <el-form-item label="合同附件" prop="icon">
          <el-upload
            accept=".pdf, .jpg, .jpeg, .png"
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="(file, fileList) => {handleRemove(file, fileList, this.dform.hetong)}"

            :http-request="httpRequest"
            :multiple="false"
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="dform.hetong">
            <el-button :disabled='addimgtype' size="small" type="primary" @click="upyupefun('合同附件')">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="发票附件" prop="icon">
          <el-upload
            accept=".pdf, .jpg, .jpeg, .png"
            class="upload-demo"
            action=""
            :on-preview="handlePreview"
            :on-remove="(file, fileList) => {handleRemove(file, fileList, this.dform.fapiao)}"

            :http-request="httpRequest"
            :multiple="false"
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="dform.fapiao">
            <el-button :disabled='addimgtype' size="small" type="primary" @click="upyupefun('发票附件')">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button :disabled='addimgtype' type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { GetLeaseList, AddRentIns, DelRentIns, EditRentIns, GetautoList } from '../../../api/car'
import { Upimg } from '@/api/upimg'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      sosuotext: '',
      addimgtype: false, // 上传控制
      dialogVisible: false, // 弹窗控制
      dform: {
        hetong: [], // 保单附件
        fapiao: [], // 发票
        name: 0,
        data1: 0,
        cost: 0,
        data2: 0
      },
      // 弹窗按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        // name: [{ message: '请选择车牌', trigger: 'blur' }],
        date1: [{ message: '请选择日期', trigger: 'blur' }],
        date2: [{ message: '请选择时长', trigger: 'blur' }]
        // cost: [{ message: '请输入费用', trigger: 'blur' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 节流
      timer: null,
      // 列表数据
      tableData: [],
      carList: [] // 车辆列表
    }
  },
  mounted() {
    this.getleaselistfun()
  },
  methods: {
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.getleaselistfun()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.getleaselistfun()
    },
    editfun(ev) {
      // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      this.dform.lease_money = this.dform.lease_money !== undefined ? this.dform.lease_money / 100 : 0
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) {
      // 打开查看

      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delrentinsfun(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 搜索
    async sosuofun() {
      const { data } = await GetAutoInsList({ key: this.sosuotext, ...this.pageData })
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 搜索匹配
    querySearch(queryString, cb) {
      this.getcarpaifun(queryString, cb)

      // 调用 callback 返回建议列表的数据
    },
    // 点击选项后
    openxuanfun(e) {
      this.dform.auto_id = e.id
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      // this.getallfun()
    },
    // 删除图片
    handleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    handlePreview(file) {},
    // eslint-disable-next-line no-unused-vars
    handleExceed(hetong, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },

    // 图片上传
    async httpRequest(options) {

      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      switch (this.steupdata) {
        case '合同附件':
          this.dform.hetong.push({ name: '合同附件', url: res.data[0].url })
          break
        case '发票附件':
          this.dform.fapiao.push({ name: '发票附件', url: res.data[0].url })

          break
      }

      this.addimgtype = false

      // // 图片上传成功后把图片push到scroll中用于修改
      // console.log(res)
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':

          this.$refs['dform'].validate(v => {
            if (v) {
              this.addrentinsfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editrentinsfun(this.dform)
            }
          })

          break
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {
        hetong: [], // 保单附件
        fapiao: [] // 发票
      }
      this.tantype = 'add'
    },
    // 请求区
    // 获取
    async getleaselistfun() {
      const { data: res } = await GetLeaseList(this.pageData)

      this.getanpostfun(res, '获取')
      this.tableData = res.data.list.list
      console.log(this.tableData)
      this.pageData.count = res.data.list.count
      this.pageData.page = res.data.list.page * 1
    },
    // 添加
    async addrentinsfun(v) {
      var newdata = JSON.parse(JSON.stringify(v))
      newdata.lease_money = newdata.lease_money !== undefined ? newdata.lease_money * 100 : 0
      const { data } = await AddRentIns(newdata)
      this.getanpostfun(data, '添加', this.getleaselistfun())
    },
    // 删除
    async delrentinsfun(id) {
      const { data } = await DelRentIns({ id })
      this.getanpostfun(data, '删除', this.getleaselistfun())
    },
    async editrentinsfun(ev) {
      var newdata = JSON.parse(JSON.stringify(ev))
      newdata.lease_money = newdata.lease_money !== undefined ? newdata.lease_money * 100 : 0
      const { data } = await EditRentIns(newdata)
      this.getanpostfun(data, '修改', this.getleaselistfun())
    },
    // 获取车辆搜索
    async getcarpaifun(key, cd) {
      const { data: res } = await GetautoList({ key, list_type: 'all', use_method: 1 })
      cd(res.data.list)
    },
    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
